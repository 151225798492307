<script lang="ts">
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import { override } from '$src/utils/classnames'
	import { twMerge } from 'tailwind-merge'

	let className: string = ''
	export { className as class }

	export let title: string

	$: fixedTitle = fixTitleCharacters(title)

	$: computedClassName = twMerge(
		override(
			'font-normal text-sm lg:text-xl tracking-tighter truncate p-0 bg-transparent w-full text-black dark:text-white',
			className,
		),
	)
</script>

<div class={computedClassName}>
	{@html fixedTitle ?? 'No Title'}
</div>
